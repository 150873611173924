import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.section`
  &.color-green {
    background: #E5F2F1;

    h2 {
      color: #07605B;
    }
  }
`

export const LinksList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  
  a {
    border: 1px solid ${grayscale[200]};
    border-radius: 16px;
    background: ${white};
    font-family: 'Sora'; 
    color: ${grayscale['500']};
    display: block;
    font-weight: 600;
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    li {
      max-width: 310px;

      @media ${device.tablet} {
        max-width: initial;
      }
    }
  }
`
