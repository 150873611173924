import React from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../style'

// Sections
import Header from '../sections/Header/_index'
import Links from '../sections/Links/_index'
import HelpCenter from '../helpCenter/_index'

import pageContext from './pageContext.json'

const VolteParaOInterPJ = () => {
  const page = 'PJ'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header page={page} />
        <Links page={page} />
        <HelpCenter bgColor='bg-white' />
      </Layout>
    </Wrapper>
  )
}

export default VolteParaOInterPJ
