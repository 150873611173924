import React from 'react'

import ChatWindow from 'components/ChatWindow'
import { EventCaller } from 'inter-site-components'
import BabiAvatar from '../assets/images/babi-avatar.png'

import { SectionWrapper, BabiButtom } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const HelpCenter = (props: {bgColor?: string}) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <SectionWrapper className={`py-5 d-md-flex position-relative align-items-md-center justify-content-md-between ${props.bgColor}`}>
      <div className='container py-lg-5'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 col-md-5'>
            <h2 className='fs-32 fs-lg-40 text-grayscale--500 mb-md-0'>
              <span className='d-block'>Precisa de</span> atendimento?
            </h2>
          </div>
          <div className='col-12 col-md-7 col-lg-5 mt-4 mt-md-0'>
            <p className='fs-16 fs-md-18 lh-19 lh-md-22 text-grayscale--500 mb-4'>
              Você pode entrar em contato com a gente através do chat para tirar suas dúvidas.
            </p>
            <div
              onClick={() => sendDatalayerEvent({
                section: 'dobra_3',
                section_name: 'Precisa de atendimento?',
                element_action: 'click button',
                element_name: 'Fale com a Babi',
              })}
            >
              <BabiButtom className='d-lg-flex align-items-center'>
                <div className='mr-4 d-none d-lg-block'>
                  <img src={BabiAvatar} alt='Babi Avatar' width={62} height={62} />
                </div>
                <EventCaller
                  action='sendGAEvent' parameters='Chat;Botao;send'
                >
                  <button
                    className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none'
                    onClick={ChatWindow()}
                  >
                    Fale com a Babi
                  </button>
                </EventCaller>
              </BabiButtom>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default HelpCenter
