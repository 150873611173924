import React from 'react'
import Img from 'gatsby-image'

// Data
import textPF from './assets/data/dataPF.json'
import textPJ from './assets/data/dataPJ.json'
import textMEI from './assets/data/dataMEI.json'

import usePageQuery from '../../pageQuery'
import { HeroSection, ImgSize } from './style'

const Header = (props: {page: string}) => {
  const data = usePageQuery()
  let pageText = textPF

  if (props.page === 'MEI') {
    pageText = textMEI
  } else if (props.page === 'PJ') {
    pageText = textPJ
  }

  return (
    <HeroSection className='pt-4 pb-5 py-lg-0 d-md-flex position-relative align-items-md-center justify-content-md-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <ImgSize className='col-12 col-md-5 offset-lg-2 offset-xl-1 order-md-last'>
            <Img fluid={data[pageText.image].fluid} alt={pageText.altImage} />
          </ImgSize>
          <div className='col-12 col-md-7 col-lg-5 col-xl-6 pr-lg-0'>
            <h1
              className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 my-4 mt-md-0 fw-600'
              dangerouslySetInnerHTML={{ __html: pageText.title }}
            />
            <p
              className='fs-18 lh-22 text-grayscale--400 mb-0 pr-md-3 pr-lg-0'
              dangerouslySetInnerHTML={{ __html: pageText.description }}
            />
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

Header.defaultProps = {
  page: 'PF',
}

export default Header
