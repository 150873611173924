import React, { Fragment } from 'react'

import Link from 'src/components/GatsbyLinkWrapper'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import LinksPF from '../../assets/data/LinksPF.json'
import LinksMEI from '../../assets/data/LinksMEI.json'
import LinksPJ from '../../assets/data/LinksPJ.json'

import { Container, LinksList } from './style'

type LinksProps = {
  title: string;
  links: {
    motivo: string;
    url: string;
  }[];
}

const Links = (props: {page: string}) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  let pageText = LinksPF
  let color = ''

  if (props.page === 'MEI') {
    pageText = LinksMEI
  } else if (props.page === 'PJ') {
    pageText = LinksPJ
    color = 'color-green'
  }

  function handleClickTag (item: {motivo: string; url: string}) {
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Por que você não está usando a sua conta?',
      element_action: 'click button',
      element_name: item.motivo,
      redirect_url: window.location.href + item.url,
    })
  }

  return (
    <Container className={`py-5 d-flex position-relative justify-content-md-between align-items-center ${color}`}>
      <div className='container py-xl-5'>
        <div className='row align-items-center justify-content-md-between '>
          {pageText.map((item: LinksProps, index: number) => (
            <Fragment key={index}>
              <div className='col-12 col-md-6 col-lg-5 col-xl-4 pr-lg -5'>
                <h2 className='fs-24 fs-md-40 lh-30 lh-md-50 text-grayscale--500 mb-4'>
                  {item.title}
                </h2>
              </div>
              <div className='col-12 col-md-6'>
                <LinksList className='pl-0'>
                  { Object.values(item.links).map((value: {motivo: string; url: string}) => (
                      value.motivo === 'Tive problemas com o meu cartão'
                      ? (
                        <div onClick={() => handleClickTag(value)} className='my-3'>
                          <Link to={value.url} title={value.motivo} key={value.motivo}>
                            <li className='fs-16 lh-20 px-3 py-4 d-flex align-items-center justify-content-between'>
                              {value.motivo}
                              <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
                            </li>
                          </Link>
                        </div>
                      )
                      : (
                        <div onClick={() => handleClickTag(value)} className='my-3'>
                          <Link to={value.url} title={value.motivo} key={value.motivo}>
                            <li className='fs-16 lh-20 px-3 py-4 d-flex align-items-center justify-content-between'>
                              {value.motivo}
                              <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
                            </li>
                          </Link>
                        </div>
                        )
                      ),
                    )
                  }
                </LinksList>
              </div>
            </Fragment>
            ))
          }
        </div>
      </div>
    </Container>
 )
}

Links.defaultProps = {
  page: 'PF',
}

export default Links
