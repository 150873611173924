import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const HeroSection = styled.section`
  min-height: 500px;

  @media ${device.tablet} {
    min-height: 368px;
  }
  @media ${device.desktopLG} {
    min-height: 536px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    
    @media ${device.desktopXL} {
      width: 456px;
      float: right;
    }
  }
`
