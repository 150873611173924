import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  background: ${grayscale[100]};

  &.color-green {
    background: #E5F2F1;

    h2 {
      color: #07605B;
    }
  }
`

export const BabiButtom = styled.div`
  span {
    width: 100%;

    button {
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      padding-top: 5px;
      
      @media ${device.desktopXL} {
        max-width: 348px;
      }
    }
  }
`
