import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
    const pageQuery = useStaticQuery(graphql`
      query {
        headerAtivacaoClienteHome: imageSharp(fluid: {originalName: { regex: "/header-ativacao-cliente-home/" }}) {
          fluid(maxWidth: 457, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        HeaderAtivacaoClienteHomePJ: imageSharp(fluid: {originalName: { regex: "/sentimos-sua-falta-pj/" }}) {
          fluid(maxWidth: 457, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        AtivacaoClienteLimiteCreditoHeader: imageSharp(fluid: {originalName: { regex: "/ativacao-cliente-limite-credito-header/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        LimiteDeCreditoPassosMobile: imageSharp(fluid: {originalName: { regex: "/limite-de-credito-passos-mobile-pf/" }}) {
          fluid(maxWidth: 44, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        LimiteDeCreditoPassosTabletDesktop: imageSharp(fluid: {originalName: { regex: "/limite-de-credito-passos-tablet-desktop/" }}) {
          fluid(maxWidth: 824, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        AtivacaoClienteCartao: imageSharp(fluid: {originalName: { regex: "/ativacao-cliente-cartao/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        ExperienciaPodemosMelhorar: imageSharp(fluid: {originalName: { regex: "/experiencia-podemos-melhorar/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        AtivacaoClienteOutroMotivo: imageSharp(fluid: {originalName: { regex: "/outro-motivo-header/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        CartaoInterMastercardParaSuaEmpresa: imageSharp(fluid: {originalName: { regex: "/cartao-inter-mastercard-para-sua-empresa/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        } 
        LibereOuAumenteOLimitePJ: imageSharp(fluid: {originalName: { regex: "/libere-ou-aumente-o-limite-pj/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        TeveAlgumProblemaDeAcessoPfMei: imageSharp(fluid: {originalName: { regex: "/teve-algum-problema-de-acesso-pf-mei/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        TeveAlgumProblemaDeAcessoPJ: imageSharp(fluid: {originalName: { regex: "/teve-algum-problema-de-acesso-pj/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        ComoPodemosMelhorarPJ: imageSharp(fluid: {originalName: { regex: "/como-podemos-melhorar-pj/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        OQueAconteceuHeroDesktop: imageSharp(fluid: {originalName: { regex: "/o-que-aconteceu-hero-desktop/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
        AcessoAoEmailCelularCadastrado: imageSharp(fluid: {originalName: { regex: "/acesso-ao-email-celular-cadastrado/" }}) {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
     }
  `)

  return pageQuery
}

export default PageQuery
