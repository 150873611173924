const ChatWindow = () => {
  return (
    function open () {
      const height = 620
      const width = 445
      const room = window.location.pathname
      let screenLeft = 0
      let screenTop = 0

      if (window && window.screen) {
        screenLeft = (window.screen.width - width) / 2
        screenTop = (window.screen.height - height) / 3
        const params = 'status=no,dialog=yes,toolbar=no,location=no,directories=no,menubar=no,resizable=no' + ',height=' + height + ',width=' + width + ',top=' + screenTop + ',left=' + screenLeft
        const chatWindow = window.open(`/chat/?${room}`, 'Chat Online', params)
        if (chatWindow != null) {
          chatWindow.focus()
        }
      }
    }
  )
}

export default ChatWindow
